let hasInitializedChatbot = false

export default function initChatbot(lang) {
  if (typeof window === "undefined" || lang !== "sv" || hasInitializedChatbot) {
    return
  }

  const integrationID = "c7a48bd7-a12a-4c56-9144-36022747d24e"
  // English integration ID: 9d1be705-773f-456b-8e5a-f0d94b389a33

  window.watsonAssistantChatOptions = {
    integrationID, // The ID of this integration.
    region: "eu-gb", // The region your integration is hosted in.
    serviceInstanceID: "4627c0b6-72cb-438f-857b-f6809723a6ca", // The ID of your service instance.
    clientVersion: "4.5.1",
    onLoad: (instance) => {
      if (lang === "sv") {
        const customLanguagePack = {
          errors_communicating: "Ett fel uppstod i kommunkationen med {botName}.",
          errors_imageSource: "Bild ej tillgänglig.",
          errors_singleMessage: "Något gick fel med meddelandet som du just skickade. Skicka gärna ett nytt meddelande.",
          errors_ariaMessageRetrying: "Vi lyckades inte skicka ditt meddelande, men vi försöker fortfarande",
          errors_ariaMessageFailed: "Vi lyckades inte skicka ditt meddelande",
          errors_busy: "Jag är ledsen, men jag kan inte hjälpa dig just nu. Det är många som ställer frågor för tillfället. Försök gärna om en stund igen.",
          input_ariaLabel: "Meddelande att skicka",
          input_placeholder: "Skriv en fråga...",
          input_buttonLabel: "Klicka för att skicka meddelandet",
          window_title: "Chattfönster",
          window_ariaChatRegion: "Chattområde",
          window_ariaChatRegionNamespace: "Chattområde \"{namespace}\"",
          window_ariaWindowOpened: "Chattfönstret har öppnats",
          window_ariaWindowClosed: "Chattfönstret har stängts",
          launcher_isOpen: "Stäng chattfönstret",
          launcher_isClosed: "Öppna chattfönstret",
          messages_youSaid: "Du sa",
          messages_botSaid: "{botName} sa",
          messages_searchResults: "Sökresultat",
          messages_botIsTyping: "{botName} skriver",
          messages_scrollHandle: "Chatthistorik börjar",
          messages_scrollHandleDetailed: "Chatthistorik börjar. Aktivera för att fokusera på det första meddelandet, använd sedan pil-, home- och endtangenterna för att flytta dig mellan meddelanden. Tryck på escape för att avsluta.",
          messages_scrollHandleEnd: "Chatthistorik slutar",
          messages_scrollHandleEndDetailed: "Chatthistorik slutar. Aktivera för att fokusera på det sista meddelandet, använd sedan pil-, home- och endtangenterna för att flytta dig mellan meddelanden. Tryck på escape för att avsluta.",
          buttons_cancel: "Avbryt",
          options_select: "Välj ett alternativ",
          options_ariaOptionsDisabled: "Dessa alternativ är inaktiverade och kan inte väljas",
          header_previewLinkTitle: "Förhandsvisning av assistens",
          header_ariaBotAvatar: "{botName} avatarbild",
          homeScreen_conversationStarterLabel: "Börja",
          icon_ariaUnreadMessages: "Det finns {count, number} {count, plural, one {oläst} other {olästa}} {count, plural, one {meddelande} other {meddelanden}}",
          showMore: "Visa fler",
          showLess: "Visa färre",
          suggestions_ariaListLabel: "Förslagslistan som innehåller fler alternativ.",
          suggestions_ariaButtonToClose: "Förslagsknapp. Klicka för att stänga förslagslistan.",
          suggestions_ariaButtonToOpen: "Förslagsknapp. Klicka för att öppna förslagslistan.",
          suggestions_ariaAnnounceButtonShow: "Klicka på förslagsknappen för fler alternativ.",
          suggestions_ariaAnnounceListOpen: "Förslagslistan har öppnats. Det finns {count, number} förslag.",
          suggestions_ariaAnnounceListClosed: "Förslagslistan har stängts.",
          showMoreResults: "Visa fler resultat",
          //disclaimer_title: "Disclaimer",
          //disclaimer_accept: "I accept",
          //disclaimer_decline: "I do not accept",
        }

        instance.updateLanguagePack(customLanguagePack)
      }

      instance.render()
    },
  }

  setTimeout(() => {
    const t = document.createElement("script")
    t.src = `https://web-chat.global.assistant.watson.appdomain.cloud/versions/${window.watsonAssistantChatOptions.clientVersion}/WatsonAssistantChatEntry.js`
    document.head.appendChild(t)
    hasInitializedChatbot = true
  })
}
