import React, { useState } from "react"
import styled from "styled-components"

import ModalWrapper from "./ModalWrapper"
import Tryggwebb from "../Tryggwebb"
import TryggwebbChatbot from "../TryggwebbChatbot"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`

const StyledTryggwebbChatbot = styled(TryggwebbChatbot)`
  position: absolute;
  bottom: 0;
  right: 8rem;

  @media (min-width: 375px) {
    right: 9rem;
  }
`

export default function TryggwebbModal({ lang, onClose, ...props }) {
  const [chatWindowIsOpen, setChatWindowIsOpen] = useState(false)

  const closeFn = chatWindowIsOpen ? () => null : onClose

  return (
    <ModalWrapper standardUi={false} onClose={closeFn}>
      {!chatWindowIsOpen && <Bg onClick={closeFn} />}
      <Tryggwebb
        chatWindowIsOpen={chatWindowIsOpen}
        chatbot={(
          <StyledTryggwebbChatbot
            lang={lang}
            onOpen={() => setChatWindowIsOpen(true)}
            onClose={() => setChatWindowIsOpen(false)}
            closeModal={closeFn}
          />
        )}
        onClose={closeFn}
        {...props}
      />
    </ModalWrapper>
  )
}
