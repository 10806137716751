import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import initChatbot from "../utils/init-chatbot"
import trackEvent from "../utils/track-event"
import { useStore } from "../hooks/store"

import CookieIcon from "./icons/Cookie"
import CookieLink from "./CookieLink"

import closePlaster from "../images/close-plaster.svg"
import cloudborder from "./../images/cloud-border-image.svg"
import cloudborderRed from "./../images/cloud-border-image-red.svg"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`

const Wrapper = styled.div`
  left: 43%;

  .WatsonAssistantChatHost {
    display: block;
  }

  #WACContainer.WACContainer .WACLauncher__ButtonContainer {
    position: static;
  }

  #WACContainer.WACContainer button.WACLauncher__Button.WAC__button--base {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    width: auto !important;
    height: auto !important;
    background: black;
    border-radius: 99px;
    color: white;
    padding: 0.5rem 1rem;
    min-height: 3.25rem;
    font-size: 0.9em;

    svg {
      vertical-align: middle;
      fill: currentColor;

      @media (max-width: 400px){
        width: 24px;
        height: 24px;
      }
    }

    :hover,
    :focus {
      background: #B80000;
      color: white;
      outline: 0;
    }

    :active {
      box-shadow: inset 0 0 0 1px #f4f4f4;
      opacity: 0.8;
    }

    @media (max-width: 374px) {
      padding: 0.4rem;

      svg {
        width: 1.6rem;
      }
    }
  }

  #WACContainer.WACContainer button.WACLauncher__Button.WAC__button--base {
    &::after {
      content: "${props => props.chatButtonText}";
      display: inline-block;
      margin-left: 0.5rem;
      white-space: nowrap;
      line-height: 2;
    }
  }

  #WACContainer.WACContainer {
    .WACWidget {
      &.WACWidget {
        background: transparent !important;
      }

      &.WACWidget--defaultElement {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .WACWidget .WACWidget__animationContainer {
      overflow: visible;
      margin: 2rem auto auto;
      width: 96%;

      @media (max-width: 500px) {

      }
    }

    button.WAC__button--base {
      border-radius: 15px;
    }

    /* whole element backdrop */
    .WAC {
      background-color: transparent;
    }

    .WAC__headerContainer {
      border: 0;
      background-color: transparent;
    }

    /* Header elements */
    #WAC__header { position: relative; }

    #WAC__header > img {
      position: absolute;
      bottom: -33%;
      min-height: 5rem;
      min-width: 5rem;
      z-index: 2;
    }

    #WAC__header-name {
      color: #333;
      opacity: 0;
    }

    .WAC__header-close {
      text-indent: 9999;

      /* &::after {
        content: url(${closePlaster});
        background: black;
        border-radius: 999px;
        height: auto;
        transition: transform .3s ease-in;
        filter: drop-shadow(0 0 10px rgba(0,0,0,0.5));
      }

      &:hover::after {
        transform: scale(1.2);
        filter: drop-shadow(0 0 7px rgba(0,0,0,0.7));
      } */
    }

    .WAC__header .WAC__headerButtons #WAC__header-close.WAC__button--ghost:enabled {
      background: black;
      content: url(${closePlaster});
    }

    .WAC__header .WAC__headerButtons #WAC__header-close.WAC__button--ghost:enabled:hover {
      background: #B80000;
      content: url(${closePlaster});
    }

    /* dialogue and input field */
    .WAC__messagesAndInputContainer,
    .WACWidget .WACWidget__animationContainer,
    .WACWidget.WACWidget--defaultElement {
      border-radius: 15px;
      box-shadow: none;
    }

    .WACMessagesContainer__NonInputContainer {
      border-radius: 15px;
      background: white;
    }

    .WAC__SentMessageProgress {
      border: none;
    }

    .WACMessagesContainer__NonInputContainer {
      box-shadow: 0 5px 1rem rgba(0,0,0,0.3);;
    }

    #WAC__inputContainer {
      margin-top: 0.4rem;
      border-radius: 15px;
      box-shadow: 0 5px 1rem rgba(0,0,0,0.3);
    }

    .WACAlternateSuggestionsContainer {
      border-top: none;
    }

    #WACContainer--render {
      font-family: ${(props) => props.theme.secondaryFont} !important;
    }
  }

  .WatsonAssistantChatHost {
    width: auto !important;
    height: auto !important;
  }
`

const CookieMessage = styled.div`
  position: absolute;
  bottom: 0;
  left: 43%;
  padding: 0.5rem 1.25rem 0.5rem 3.5rem;
  border-radius: 9999px;
  background: black;
  color: white;
  font-size: 0.875rem;
  transform: translateY(100%);

  p {
    margin: 0;
    font-weight: bold;
  }

  a {
    color: white;
    white-space: nowrap;
  }

  svg {
    position: absolute;
    top: 0.5625rem;
    left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  @media (max-width: 419px) {
    left: 38%;

    a {
      font-size: 0.75rem;
    }
  }
`

let chatHostEl

export default function TryggwebbChatbot({ lang, onOpen, onClose, closeModal, ...props }) {
  const intl = useIntl()
  let chatHostInterval
  let closeButton = null
  const ref = useRef(null)
  const componentIsMounted = useRef(true)
  const [store] = useStore()
  const [chatbotIsInitialized, setChatbotIsInitialized] = useState(!!chatHostEl)
  const [chatButtonIsAppended, setChatButtonIsAppended] = useState(false)
  const [chatWindowIsOpen, setChatWindowIsOpen] = useState(false)

  const track = (value) => {
    trackEvent({ category: "Buttons", action: "Button Click", name: `${value ? "Open" : "Close"} Watson Assistant Chat` })
  }

  const appendChatButton = () => {
    // Workaround for IE11
    const wrapper = ref.current || typeof document !== "undefined" && document.getElementById("chatbot-wrapper")

    if (wrapper) {
      wrapper.appendChild(chatHostEl)
      setChatButtonIsAppended(true)
    }
  }

  const handleChatButtonClick = () => {
    track(true)
    setChatWindowIsOpen(true)
    onOpen()
  }

  const handleChatCloseClick = (e) => {
    if (componentIsMounted.current) {
      setChatWindowIsOpen(false)
      onClose()

      const launcherButton = document.getElementById("WACLauncher__Button")

      if (launcherButton) {
        track(false)
        setTimeout(() => { launcherButton.focus() })
      }
    }
  }

  const handleKeyDown = (e) => {
    if (chatWindowIsOpen && closeButton && e.keyCode === 27) {
      closeButton.click()
    }
  }

  const bindCloseButton = () => {
    setTimeout(() => {
      if (!componentIsMounted.current) {
        return
      }
      closeButton = document.getElementById("WAC__header-close")

      if (closeButton) {
        closeButton.addEventListener("click", handleChatCloseClick)
      } else {
        bindCloseButton()
      }
    }, 100)
  }

  const handleBgClick = () => {
    if (!closeButton) {
      closeButton = document.getElementById("WAC__header-close")
    }
    if (closeButton) {
      closeButton.click()
    }
  }

  useEffect(() => {
    if (store.cookies.chatbot) {
      if (chatbotIsInitialized) {
        appendChatButton()
      } else {
        initChatbot(lang)
        setChatbotIsInitialized(true)
      }
    }

    return () => {
      if (closeButton) {
        closeButton.removeEventListener("click", handleChatCloseClick)
      }
      componentIsMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (store.cookies.chatbot) {
      if (chatbotIsInitialized) {
        appendChatButton()
      } else {
        initChatbot(lang)
        setChatbotIsInitialized(true)
      }
    }
  }, [store.cookies.chatbot])

  useEffect(() => {
    if (chatbotIsInitialized && !chatHostEl) {
      let i = 0

      chatHostInterval = setInterval(() => {
        if (!componentIsMounted.current) {
          clearInterval(chatHostInterval)
          return
        }
        chatHostEl = document.querySelector(".WatsonAssistantChatHost")

        if (chatHostEl) {
          clearInterval(chatHostInterval)
          appendChatButton()
        } else if (i === 50) {
          clearInterval(chatHostInterval)
        }

        i++
      }, 100)

      return () => { clearInterval(chatHostInterval) }
    }
  }, [chatbotIsInitialized])

  useEffect(() => {
    if (chatButtonIsAppended && chatHostEl) {
      const launcherButton = document.getElementById("WACLauncher__Button")

      if (launcherButton) {
        launcherButton.addEventListener("click", handleChatButtonClick)
        return () => { launcherButton.removeEventListener("click", handleChatButtonClick) }
      }
    }
  }, [chatButtonIsAppended])

  useEffect(() => {
    if (chatWindowIsOpen) {
      if (!closeButton) {
        bindCloseButton()
      }
      document.addEventListener("keydown", handleKeyDown)
    } else {
      document.removeEventListener("keydown", handleKeyDown)
    }

    return () => { document.removeEventListener("keydown", handleKeyDown) }
  }, [chatWindowIsOpen])

  return (
    <>
      {chatWindowIsOpen && <Bg onClick={handleBgClick} />}
      {store.cookies.chatbot ?
        <Wrapper ref={ref} id="chatbot-wrapper" chatButtonText={intl.formatMessage({ id: "askMe" })} {...props}>
          {/*!chatButtonIsAppended &&
            <div className="loading-button-container">
              <span className="fake-button">
                {intl.formatMessage({ id: "loading" })}...
              </span>
            </div>
          */}
        </Wrapper>
      : (lang === "sv" &&
        <CookieMessage>
          <CookieIcon />
          <p>{intl.formatMessage({ id: "chatTurnedOff" })}</p>
          <CookieLink
            lang={lang}
            text={intl.formatMessage({ id: "checkCookieSettings" })}
            linkToSettings
            onClick={closeModal}
          />
        </CookieMessage>
      )}
    </>
  )
}
