import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import useReducedMotion from "./reducedMotion"
import { useStore } from "./store"
import trackEvent from "../utils/track-event"

import Tryggwebb from "../components/Tryggwebb"
import HildingVilding from "../components/HildingVilding"
import CiceronSpeechBubble from "../components/CiceronSpeechBubble"
import CiceronAudioPlayer from "../components/CiceronAudioPlayer"
import CloseIcon from "../components/icons/Close"

const StyledCiceronAudioPlayer = styled(CiceronAudioPlayer)`
  position: absolute;
  top: -3rem;
  left: 1rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: -3rem;
  right: -2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.6rem;
  border: none;
  border-radius: 50%;
  background: white;

  svg {
    width: 1rem;
    height: 1rem;
  }
`

const useCiceron = ({
  audiofile,
  text,
  onClose,
  closeTimeout = 0,
  chatWindowIsOpen,
  headline,
  chatbot,
}) => {
  const intl = useIntl()
  const [store] = useStore()
  const shouldReduceMotion = useReducedMotion()
  const [isClosing, setIsClosing] = useState(false)
  const [audio, setAudio] = useState(null)
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [animate, setAnimate] = useState(null)
  const componentIsMounted = useRef(true)
  let currentTimeInterval

  const onSoundEnded = () => {
    setAudioPlaying(false)
  }

  const onDurationChange = () => {
    setDuration(audio.sound.duration || 0)
  }

  useEffect(() => {
    if (audiofile) {
      setAudio(createAudio(audiofile))
    }

    return () => {
      componentIsMounted.current = false
    }
  }, [])

  useEffect( ()=> {
    if (audio) {
      audio.sound.addEventListener("ended", onSoundEnded)
      audio.sound.addEventListener("durationchange", onDurationChange)

      return () => {
        audio.sound.removeEventListener("ended", onSoundEnded)
        audio.sound.removeEventListener("durationchange", onDurationChange)
      }
    }
  }, [audio])

  useEffect(() => {
    if (audioPlaying) {
      currentTimeInterval = setInterval(() => {
        setCurrentTime(audio.sound.currentTime)
      }, 1000)
    } else {
      clearInterval(currentTimeInterval)
    }

    return () => { clearInterval(currentTimeInterval) }
  }, [audioPlaying])

  const track = (action) => {
    trackEvent({ category: "Audio", action, name: audiofile })
  }

  const toggle = () => {
    if (audio.sound.paused) {
      if (audio.context) {
        audio.context.resume()
        setAnimate(true)
      }

      audio.sound.play()
      setAudioPlaying(true)
      track("Play")
    } else {
      setAnimate(false)
      setAudioPlaying(false)
      track("Pause")
    }
  }

  const createAudio = url => {
    let sound = new Audio(url)
    const props = {
      controls: true,
      loop: false,
      autoPlay: false,
      crossOrigin: "anonymous",
      src: url,
    }

    sound = Object.assign(sound, props)

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    let context
    let source
    let analyser

    if (AudioContext && (context = new AudioContext())) { // AudioContext object instance
      analyser = context.createAnalyser() // AnalyserNode method

      // Re-route audio playback into the processing graph of the AudioContext
      source = context.createMediaElementSource(sound)
      source.connect(analyser)
      analyser.connect(context.destination)
    }

    return {
      sound,
      source,
      context,
      analyser,
    }
  }

  const audioPlayer = audiofile ? (
    <StyledCiceronAudioPlayer
      toggle={toggle}
      audioPlaying={audioPlaying}
      duration={duration}
      currentTime={currentTime}
    />
  ) : null

  const handleClose = (() => {
    if (closeTimeout) {
      setIsClosing(true)

      setTimeout(() => {
        if (componentIsMounted.current) {
          setIsClosing(false)
          onClose()
        }
      }, shouldReduceMotion || !store.animationsEnabled ? 0 : 3000)
    } else {
      onClose()
    }
  })

  const closeButton = (
    <CloseButton onClick={handleClose}>
      <span className="structural">{intl.formatMessage({ id: "close" })}</span>
      <CloseIcon fill="#000" />
    </CloseButton>
  )

  const speechBubble = text ? (
    <CiceronSpeechBubble
      dialogue={text}
      headline={headline}
      audioPlayer={audioPlayer}
      closeButton={closeButton}
      chatWindowIsOpen={chatWindowIsOpen}
      chatbot={chatbot}
      className="ciceron-speech-bubble"
    />
  ) : null

  return {
    audio,
    animate,
    noAnimation: shouldReduceMotion || !store.animationsEnabled,
    speechBubble,
    className: isClosing ? "disabled" : "",
  }
}

export default useCiceron
